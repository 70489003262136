import { Box, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import { UserContext } from "src/context/User";

const useStyles = makeStyles((theme) => ({
  imgSection: {
    "& img": {
      // maxWidth: "230px",
      width: "90px",
      // height:"68px",
      innerHeight: "90px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
    },

    "@media(max-width:991px)": {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      // margin: "0 auto",
    },
  },
}));
const Logo = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);

  return (
    <Box className={classes.imgSection}>
    {/* {themeSeeting.settings.theme === "DARK" && (
      //   <img
      //   onClick={() => history.push("/")}
      //   src={"/images/infinity_logo.png"}
      //   alt="Logo"
      //   style={{ cursor: "pointer", width: "112px", paddingLeft: "35px", height: "35px" ,backgroundRepeat:'no-repeat'}}
      //   {...props}
      // />
      <></>


      )}  */}
      {themeSeeting.settings.theme === "LIGHT" && (
        <img
          onClick={() => history.push("/")}
          src={"/images/infinity_logo.png"}
          alt="Logo"
          style={{ cursor: "pointer", width: "60px", paddingLeft: "35px", height: "60px",backgroundRepeat:'no-repeat' }}
          {...props}
        />
      )}
    </Box>
  );
};

export default Logo;
