import React, {  useEffect, } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from "@material-ui/core";
import Logo from "src/component/Logo";
import {FaSignOutAlt } from "react-icons/fa";
import NavItem from "./NavItem";
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import {MdOutlineHomeRepairService , MdOutlineContentCopy} from "react-icons/md";
import {MdOutlineCategory,MdOutlineBrandingWatermark} from "react-icons/md";
import {BiLogoBlogger} from "react-icons/bi";
import {BsBag} from "react-icons/bs";
import {FaQuestion} from "react-icons/fa";
import {AiOutlineDashboard,AiOutlineUnlock,AiOutlineContacts} from "react-icons/ai";
const sections = [
  {
    items: [
      {
        title: "Dashboard ",
        icon: AiOutlineDashboard,
        href: "/dashboard",
      },
      {
        title: "Service Management ",
        icon: MdOutlineHomeRepairService,
        href: "/service-management",
      },
      {
        title: "Category Management",
        icon: MdOutlineCategory,
        href: "/category-management",
        // tooltip:"Coming soon"
      },
      {
        title: "Portfolio Management",
        icon: BsBag,  
        href: "/portfolio-management",
      },
      {
        title: "Blog Management",
        icon: BiLogoBlogger,
        href: "/blog-management",
      },
      {
        title: "Brand Management",
        icon: MdOutlineBrandingWatermark,
        href: "/brand-management",
      },
    
      {
        title: "Content Management",
        icon: MdOutlineContentCopy,
        items: [
          {
            title: "Terms and Conditions",
            href: "/terms-conditions",
          },
          {
            title: "Privacy-policy",
            href: "/privacy-policy",
          },
        ],
      },  
      
      {
        title: "Faq Management",
        icon: FaQuestion,
        href: "/faq-management",
      },
      {
        title: "Contact Us",
        icon: AiOutlineContacts,
        href: "/contact-us",
      },
      {
        title: "Change Password",
        icon: AiOutlineUnlock,
        href: "/email",
      },

    ],
  },
];
const sectionsBelow = [
  {
    items: [
      // {
      //   title: "Terms and Conditions",
      //   //icon: PieChartIcon,
      //   href: "/terms",
      // },
      // {
      //   title: "Logout",
      //   href: "/dashboard",
      //   icon: IoLogOut,
      // },
      // {
      //   title: "Privacy Policy",
      //   //icon: PieChartIcon,
      //   href: "/privacy-policy",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#000 !important",
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",

    background: `transparent`,
    border: "none",
    position: "revert",
    boxShadow: "5px 0 5px -2px #262f3f"

  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: `${theme.palette.text.primary} !important`,
  },

  Terms: {
    color: theme.palette.text.primary,


    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    "& .MuiButton-label": {
      padding: "10px",
      paddingLeft:"10px"
    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "#fff !important",
        background: "#3A96DD",
        padding: "10px",
        borderRadius: "9px",
        fontWeight: theme.typography.fontWeightRegular,
        "& $title": {
          fontWeight: theme.typography.fontWeightMedium,
        
        },
        "& $icon": {
          color: "#fff !important",
       
        },
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
     
      },
    },
  },

  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },

  listitem:{
    color: "golden",
    "& .makeStyles-button-51": {
      color: "#fff !important",
      fontSize:"13px",
    },
    "& .makeStyles-title-53": {
      // color: "#FCC200 !important",
      fontSize:"13px"
    },
    "& .makeStyles-button-56": {
      color: "#fff !important",
      fontSize:"13px ",
    },
}
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location?.pathname]);

  const content = (
    <Box
      className="srollBoxNav"
      style={{ overflowY: "auto",backgroundColor:"#313C52",   height:"100%",
      display:"flex",
      flexDirection:"column" }}
    >
      <Hidden mdDown>
        <Box
          mb={-1}
          display="flex"
          justifyContent="center"

        >
          {/* <Logo
            onClick={() => history.push("/")}
            alt="Logo"
            style={{
              paddingTop: "10px",

              cursor: "pointer",
              width: "106px",
            }}
          /> */}
        </Box>
      </Hidden>

      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box my={3} style={{
          marginTop: "90px",
          position: "fixed",
          // margintop: "65px",
        }}>
          {sections.map((section, i) => (
            <List  className={classes.listitem}
              key={`menu${i}`}
              style={section.items.title == "Content Management" ?{
              color:"#fff"
              }:{}}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
              
            >
              {renderNavItems({
                items: section.items,
                pathname: location?.pathname,
              })}
            </List>
         
          ))}
          <Box style={{ height: "100px !important" }}>
            {/* <ChartList height="38%" /> */}
          </Box>
        </Box>

        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                 
                  <Button
                    fullWidth
                    key={i}
                    className={classes.Terms}
                    style={{
                      justifyContent: "start",
                      marginTop: "-23px",
                      // paddingLeft: 36,
                      borderRadius: 0,
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                   
                  >
                    <FaSignOutAlt
                      style={{ fontSize: "15px" }}
                      className={classes.icon}
                    />
                    {itemList.title}
                  </Button>
                
                );
              })}

              {/* {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })} */}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          style={{ overflowY: "hidden" }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        // style={{ overflowY: "hidden" }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
