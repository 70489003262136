import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar/index";
import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#000",
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    minHeight: "100vh",
    background: "#313C52",

  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    [theme.breakpoints.up("lg")]: {
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    color: theme.palette.text.primary,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    maxWidth: "100%",
    minHeight: "100vh",

  },
  content: {
    paddingTop: "106px",
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    padding: "60px 30px 10px ",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 16px 10px ",
    },
  },
}));
const DashboardLayout = ({ children }) => {
  const themeSeeting = React.useContext(SettingsContext);

  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [themeChange, seThemeChange] = useState("");
  useEffect(() => {
    const activeTheme = themeSeeting?.themekey?.theme;
    seThemeChange(activeTheme);
  }, []);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <div style={location.pathname=="/account-setting" || location.pathname=="/team"  ? {display:"none"} : {display:"block",boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.5)"}}>
      <NavBar
      
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      /></div>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-scroll">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;

