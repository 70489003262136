// const url = "http://172.16.6.43:8051";
// const url = "http://";
const loginURL = "https://java-infinity.mobiloitte.io"
// const loginURL = "http://172.16.2.42:4003"


const baseURL = "https://java-infinity.mobiloitte.io/account"

const localport = "http://172.16.2.42:4003/account"


const baseURl1 = "https://java-infinity.mobiloitte.io/account/admin"
// const baseURl1 = "http://172.16.2.42:4003/account/admin"


const ApiConfig = {
  login: `${loginURL}/auth`,
  resetpassword: `${baseURL}/reset-password`,
  blogCount: `${baseURl1}/total-blogs-count`,
  portfolioCount: `${baseURl1}/total-portfolio-count`,
  brandCount: `${baseURl1}/total-brand-count`,
  serviceCount: `${baseURl1}/total-service-count`,

  allservices: `${baseURL}/services`,
  allcategorySearch: `${baseURL}/all-categories-search`,
  getAllCategies: `${baseURL}/get-all-categories`,
  allportfolio: `${baseURL}/all-portfolio`,
  portfoliowithsearch: `${baseURL}/all-portfolio`,
  allblogs: `${baseURL}/all-blogs`,
  allbrand: `${baseURL}/all-brands`,

  deleteservice: `${baseURl1}/delete-service`,
  deleteportfolio: `${baseURl1}/delete-portfoliio`,
  deletecategory: `${baseURl1}/delete`,
  deleteblog: `${baseURl1}/delete-blog-by-Id`,
  deletebrand: `${baseURl1}/delete-brand`,



  editservice: `${baseURl1}/update-service`,
  editcategory: `${baseURl1}/update`,
  editportfolio: `${baseURl1}/update-portfolio-by-id`,

  editbrand: `${baseURl1}/update-brand`,
  updateblog: `${baseURl1}/update-blog-by-Id`,



  addbrand: `${baseURl1}/create-brand`,
  addservice: `${baseURl1}/create-service`,
  addportfolio: `${baseURl1}/create-portfolio`,
  addblog: `${baseURl1}/create-blog`,

  addcategory: `${baseURl1}/create`,
  allcategory: `${baseURL}/all-categories-search`,


  baseimgurl: `${baseURL}/upload-file-base64`,
  baseimgurlnew: `${baseURL}/upload-file`,

  allstaticcontent: `${baseURL}/get-all-static-content`,

  termsconditions: `${baseURL}/get-static-content`,
  updateterms: `${baseURl1}/static-content/update-static-content`,


  getemail: `${baseURL}/get-email`,

  emailverification: `${baseURL}/forget-password`,
  changepassword: `${baseURL}/change-password`,

  myaccount: `${baseURL}/my-account`,


  getfaq: `${baseURL}/faq-list`,
  deletefaq: `${baseURL}/admin/delete-faq`,
  faqById: `${baseURL}/get-faq`,
  faqAdd: `${baseURl1}/add-faq`,

  editUserProfile: `${baseURL}/update-profile-data`,

  contactus: `${baseURL}/get-contact-us`,
  allcontactus: `${baseURL}/get-all-contact-us-details`,

};
export default ApiConfig;

