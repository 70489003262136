/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  List,
  makeStyles,
  Avatar,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { UserContext } from "src/context/User";
import {
  FaWallet,
  FaSignOutAlt,
  FaUserEdit,
  FaSignInAlt,
} from "react-icons/fa";
import Axios from "axios";
import ApiConfig from "src/config/APIConfig";
import { toast } from "react-toastify";

const sections = [
  {
    title: "Account setting",
    href: "/account-setting",
    icon: FaUserEdit,
  },

  {
    title: "Logout",
    href: "/dashboard",
    icon: FaSignOutAlt,
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    top: "30px",
    right: "43px",
    border: "1px solid rgba(0, 0, 0, 0.5)",
    padding: "1px",
    overflow: "unset",
    position: "absolute",
    background: "#FFFFFF",
    borderRadius: "10px",
  },
  balanceDiv: {
    display: "flex",
    justifyContent: "space-between",
    // padding: "34px",
    background: "#3E3E3E",
    borderRadius: "10px",
    alignItems: "center",
  },
  balanceSecondDiv: {
    display: "flex",
    gap: "15px",
  },
  menuListText: {
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25px",
    textTransform: "capitalize",
    // color: "#FFFFFF",
    cursor: "pointer",
  },
  balanceText: {
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "38px",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  logoutButton: {
    background: "linear-gradient(180.99deg, #2FF3FF -25%, #1E92AA 141.48%)",
    borderRadius: "5px",
    padding: "12px 0",
    textAlign: "center",
    border: "none",
    cursor: "pointer",
    marginTop: "10px"
  },
  logoutButton: {
    padding: "10px",
    // border: '2px solid black',
    borderRadius: "10px",
    "& .makeStyles-logoutButton-30": {
      backgroundColor: "",
    }
  }
}));

const NavBar = () => {
  const classes = useStyles();
  useEffect(() => {
    user.getViewMyProfile();
    user.exchangeWallet();
  }, []);
  const [rightBar, setRightBar] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const user = useContext(UserContext);
  const confirmationHandler = () => {
    history.push("/");
    window.localStorage.removeItem("token");
    window.localStorage.clear();
  };
  const editProfile = () => {
    history.push("/view-profile");
    // window.localStorage.removeItem("token");
    // window.localStorage.clear();
  };
  const exchangeBalance = user?.exchangeBalance?.allExchangeTotal;
  const profilePic = user?.profile?.profilePic ? user?.profile?.profilePic : null;
  console.log(profilePic, "profilePic")
  const content = (
    <>
      <Box style={{ display: "grid", gap: "20px" }}>
        <Box className={classes.balanceDiv}>

          <Box className={classes.balanceSecondDiv}>
            {exchangeBalance && (
              <Typography className={classes.balanceText}>
                {exchangeBalance}
              </Typography>
            )}
            {exchangeBalance && <img src="/images/Credit card.svg" />}
          </Box>
        </Box>
      </Box>
      <Box
        onClick={() => editProfile()}
        className={classes.logoutButton}
      >
        <Typography className={classes.menuListText}>My Profile</Typography>
      </Box>
      {/* <Box
        onClick={() => confirmationHandler()}
        className={classes.logoutButton}
      >
        <Typography className={classes.menuListText}>Change Password</Typography>
      </Box> */}
      <Box
        onClick={() => confirmationHandler()}
        className={classes.logoutButton}
      >
        <Typography className={classes.menuListText}>Logout</Typography>
      </Box>
    </>
  );


  const [isloading, setLoader] = useState(false);
  const [data, setData] = useState();
  // console.log("datadata", data?.firstName);
  
  const image = data?.imageUrl
  const MyAccount = async (accessToken) => {
    setLoader(true);
    const token = window.localStorage.getItem('token');
    try {
      const res = await Axios({
        method: 'GET',
        url: ApiConfig.myaccount,

        headers: {
          Authorization: `Bearer ${token}`,
          // userId:window.localStorage.getItem("user_id")
        }
      });

      if (res.data.status === 200) {
        setLoader(false);
        setData(res.data.data)
      }
      else {

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      if (error.response && error.response.status === 401) {
        // toast.error(error.response.data.message);
      }
    }
  }

  useEffect(() => {
    MyAccount()
  }, [])
  const[base64Image,setBase64Image] = useState();
  return (
    <>
     
      <img
        style={{ width: "35px", height: "35px", borderRadius: "100%", mixBlendMode: "color-dodge",border:"1px solid #fff" }}
        className="profilePic"
        files={base64Image}
        src={image ? image : "/images/avatar.jpeg"}
        alt=""
        onClick={() => {
          setRightBar(!rightBar);
        }}
      />
     
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
        />
      )}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
