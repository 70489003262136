import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/dashboard/DashboardUpdate")),
  },
  {
    exact: true,
    path: "/service-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Service Management/ServiceManagement")),
  },
  {
    exact: true,
    path: "/add-new-service",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Service Management/AddNewService")),
  },
  {
    exact: true,
    path: "/edit-service",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Service Management/EditService")),
  },
  {
    exact: true,
    path: "/view-service",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Service Management/ViewService")),
  },
  {
    exact: true,
    path: "/edit-portfolio",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Portfolio Management/EditPortfolio")),
  },
  {
    exact: true,
    path: "/view-portfolio",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Portfolio Management/ViewPortfolio")),
  },
  {
    exact: true,
    path: "/edit-brand",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ Brand Management/EditBrand")),
  },

  {
    exact: true,
    path: "/category-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Category Management/CategoryManagement")),
  },

  {
    exact: true,
    path: "/edit-category",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Category Management/EditCategory")),
  },

  {
    exact: true,
    path: "/add-new-category",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Category Management/AddCategory")),
  },
  {
    exact: true,
    path: "/view-category",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Category Management/ViewCategory")),
  },
  {
    exact: true,
    path: "/portfolio-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Portfolio Management/PortfolioManagement")),
  },



  {
    exact: true,
    path: "/blog-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ Blog Management/BlogManagement")),
  },
  {
    exact: true,
    path: "/add-new-blog",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ Blog Management/AddNewBlog")),
  },
  {
    exact: true,
    path: "/edit-blog",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ Blog Management/EditBlog")),
  },
  {
    exact: true,
    path: "/view-blog",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ Blog Management/ViewBlog")),
  },
  {
    exact: true,
    path: "/brand-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ Brand Management/BrandManagement")),
  },
  {
    exact: true,
    path: "/add-new-brand",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ Brand Management/AddBrand")),
  },
  {
    exact: true,
    path: "/view-brand",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ Brand Management/ViewBrand")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static Content Management/StaticContentManagement")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static Content Management/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/edit-content",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static Content Management/EditContent")),
  },
  {
    exact: true,
    path: "/edit-privacy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static Content Management/EditPrivacy")),
  },

  // {
  //   exact: true,
  //   path: "/change-pasword",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Change Pasword/ConformPassword")),
  // },
  {
    exact: true,
    path: "/add-new-portfolio",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Portfolio Management/AddNewPortfolio")),
  },
  {
    exact: true,
    path: "/faq-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faq/FaqManagement")),
  },
  {
    exact: true,
    path: "/faq-view/:id",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faq/FaqView")),
  },
  {
    exact: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faq/AddFaq")),
  },
  {
    exact: true,
    path: "/view-faq",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faq/FaqView")),
  },
  {
    exact: true,
    path: "/otp-verification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Change Pasword/OtpVerification")),
  },
  {
    exact: true,
    path: "/email",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Change Pasword/Email")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  {
    exact: true,
    path: "/view-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/ViewProfile")),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Contact Us/ContactUs")),
  },


//


  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
